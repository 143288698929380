<template>
  <v-container class="general">
    <PageTitle :component="'GroupTransactions'" />
    <div id="balances-container"></div>
    <div id="payments-container"></div>
  </v-container>
</template>

<script>
import { loadConnectAndInitialize } from "@stripe/connect-js";

export default {
  computed: {
    stripePublishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    groupId() {
      return this.activeGroup.id;
    },
    groupLanguageIso() {
      return this.activeGroup.attributes.language_iso;
    },
    stripeComponentOptions() {
      if (!this.groupId) {
        return null;
      }

      return {
        publishableKey: this.stripePublishableKey,
        fetchClientSecret: this.fetchClientSecret,
        appearance: {
          colorPrimary: this.gmSecondaryColor,
          colorText: this.gmPrimaryColor,
          buttonPrimaryColorBackground: this.gmSecondaryColor,
          buttonPrimaryColorText: "#FFFFFF",
          overlays: "dialog",
        },
        locale: this.groupLanguageIso,
      };
    },
  },
  watch: {
    groupId: {
      handler() {
        this.loadStripeComponents();
      },
    },
  },
  mounted() {
    this.loadStripeComponents();
  },
  methods: {
    async fetchClientSecret() {
      try {
        const response = await this.$api.groupPayments.fetch_client_secret(
          this.groupId,
        );

        if (!response.data.client_secret) {
          return;
        }

        return response.data.client_secret;
      } catch (error) {
        this.errorMessageShow(error);
      }
    },
    loadStripeComponents() {
      if (!this.stripeComponentOptions) {
        return null;
      }

      const stripeConnectInstance = loadConnectAndInitialize(
        this.stripeComponentOptions,
      );

      const balancesContainer = document.getElementById("balances-container");
      const balancesComponent = stripeConnectInstance.create("balances");

      balancesContainer.appendChild(balancesComponent);

      const paymentsContainer = document.getElementById("payments-container");
      const paymentsComponent = stripeConnectInstance.create("payments");

      paymentsContainer.appendChild(paymentsComponent);
    },
  },
};
</script>

<style scoped></style>
